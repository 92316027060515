import React from 'react'
import styled from 'styled-components'
import { HeroProps } from 'components/flex/Hero/HeroShell'

import Ribbon from 'components/elements/Vectors/Ribbon'

const SVGWrapper = styled.div`
  @media (max-width: 1399px) {
    & svg {
      width: 100%;
    }
  }
`

const Hero: React.FC<HeroProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <SVGWrapper>
      <Ribbon image={fields.image} />
    </SVGWrapper>
  </section>
)

export default Hero
